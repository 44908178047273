import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { CrmDirectory } from './CrmDirectory';
import { CrmCompanyCreate } from './CrmCompanyCreate';

export interface CRMRoutingProps extends RouteComponentProps {}

export const CRMRouting = (props: CRMRoutingProps) => {
  return (
    <Switch>
      <Route exact path={`${props.match.path}`} component={CrmDirectory} />
      <Route exact path={`${props.match.path}/company/create`} component={CrmCompanyCreate} />
    </Switch>
  );
};

export const RateBreakRouting = compose(withRouter)(CRMRouting);
