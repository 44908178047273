import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { AxiosWrapper } from 'services/AxiosWrapper';
import { injectJwtTokenIntoHeaders } from 'services/tokenLocalStorage';
import { IBootstrapCountry } from 'store/modules/bootstrap/model';
import { ETypeValueType, ITypeValue } from './types/types';

export enum CRMEndpoints {
  EXAMPLES = 'v1/examples',
  COUNTRIES = 'v1/countries',
  TYPE_VALUES = 'v1/type-values',
}

export class CRMApiService<T extends AxiosWrapper<AxiosInstance>> {
  client: T;

  constructor(client: T) {
    this.client = client;
  }

  getExamples = async (): Promise<AxiosResponse> => {
    const endpoint = `${CRMEndpoints.EXAMPLES}`;
    return this.client.get(endpoint);
  };

  postTypeValues = async (type: ETypeValueType, { enabled }: { enabled: boolean }): Promise<AxiosResponse<ITypeValue[]>> => {
    const endpoint = `${CRMEndpoints.TYPE_VALUES}/${type}`;
    return this.client.post(endpoint, { enabled });
  };
  
  postCountries = async ({ isDestination }: { isDestination?: boolean } = { isDestination: false }): Promise<AxiosResponse<IBootstrapCountry[]>> => {
    const endpoint = `${CRMEndpoints.COUNTRIES}`;
    return this.client.post(endpoint, { isDestination });
  };
}

export const makeCRMApi = (): CRMApiService<AxiosWrapper<AxiosInstance>> => {
  const dynamicParameters = ParameterService.getParameters();
  const headers: any = {};

  const client = axios.create({
    baseURL: dynamicParameters.CRM_BASE_URL,
    headers: injectJwtTokenIntoHeaders(headers),
    withCredentials: true,
  });

  const clientWrapper = new AxiosWrapper(client);

  return new CRMApiService<AxiosWrapper<AxiosInstance>>(clientWrapper);
};
