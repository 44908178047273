export enum ETypeValueType {
  TITLE = 'title',
  COMPANY = 'company',
  CONTACT = 'contact',
  CHANNEL = 'channel',
  SOURCE = 'source',
  LEAD_STAGE = 'lead-stage',
  PERSONAL_DOCUMENT = 'personal-document',
  ROLE = 'role',
}

export interface ITypeValue {
  code: string;
  enabled: boolean;
  value: string;
}