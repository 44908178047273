import React from 'react';
import ReactDOM from 'react-dom/client';
import { Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import ReactBreakpoints from 'react-breakpoints';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { breakpoints } from 'pureUi/breakpoints';
import * as uuid from 'uuid';

import 'store/modules/fastSearch';
import './config/i18n';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import store, { history } from 'store';

import { NODE_ENV, SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE, ANALYTICS_SESSION_UUID_KEY } from 'config';

import headerMeta from 'config/meta';
import headerLink from 'config/link';
import entryRoutes from 'routing/entry';
import { getRoutes } from 'routing';

import { theme } from 'styles';
import './styles/css/core.css';
import './styles/css/tailwindcssloader.css';
import './styles/fonts/HurmeGeometricSans2.css';
import './styles/fonts/NoeDisplay.css';
import { writeToSessionStorage } from 'services/windowSessionStorage';
import { initSentry } from 'integrations/sentry/initSentry';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { TitleFavicon } from 'components/TitleFavicon';

if (NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

// sessionUuid is used by analytics 3rd parties
const sessionUuid = uuid.v4();

ParameterService.startPeriodicRefresh();
// overwrite false because we specifically want the same session ID to last over tab reloads
writeToSessionStorage(ANALYTICS_SESSION_UUID_KEY, sessionUuid, { overwrite: false });

initSentry(SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE, sessionUuid);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <ReactBreakpoints breakpoints={breakpoints}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Helmet>
            {headerMeta}
            {headerLink}
          </Helmet>
          <TitleFavicon />
          <ConnectedRouter history={history}>
            <Switch>{getRoutes(entryRoutes)}</Switch>
          </ConnectedRouter>
          {NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </Provider>
    </ThemeProvider>
  </ReactBreakpoints>
);
