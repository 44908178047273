import { call, takeLatest, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  EDIT_ACCOMMODATION_BOOKING_BUILD_REQUEST,
  EDIT_ACCOMMODATION_OPEN_MODAL,
  EDIT_ACCOMMODATION_SIMULATE_BREAKDOWN_REQUEST,
  editAccommodationBookingBuildFailedAction,
  editAccommodationBookingBuildSuccessAction,
  editAccommodationCloseModalAction,
  editAccommodationInitiateBookingBuildFailedAction,
  editAccommodationInitiateBookingBuildRequestAction,
  editAccommodationInitiateBookingBuildSuccessAction,
  EditAccommodationOpenModalAction,
  editAccommodationSetBookingBuildAction,
  editAccommodationSetCountryDataAction,
  editAccommodationSetErrorsAction,
  editAccommodationSetInitialErrorsAction,
  editAccommodationSetLastRequestedBuildAction,
  editAccommodationSetOwnershipDataAction,
  editAccommodationSetSelectedMealPlanAction,
  editAccommodationSimulateBreakdownFailedAction,
  EditAccommodationSimulateBreakdownRequestAction,
  editAccommodationSimulateBreakdownSuccessAction,
  updateBreakdownDataAfterAddOrEditAccommodation,
} from '../actions';
import { format } from 'date-fns';

import { BookingBuilderRequest, BookingBuilderResponse, makeBackendApi } from 'services/BackendApi';
import { IBookingCountryResponse, IOwnershipResponse, makeBookingManagerApi } from 'services/BookingManagerApi';
import * as BreakdownSelectors from '../selectors';
import { IStaticRate } from 'ui/AddAccommodationModal/MealPlanDropdown/types';
import { customeErrorType, IAccommodationDetails } from '../model';
import { convertMealPlanToStaticRate } from 'ui/AddAccommodationModal/helpers';
import { isEmpty } from 'lodash-es';
import { enqueueNotification } from 'store/modules/ui';
import { formatPrice } from 'utils';

export function* editAccommodationInitiateBookingBuildSaga(action: EditAccommodationOpenModalAction) {
  try {
    // we've got a React/Redux race condition due to how we're calling actions
    // and re. this saga. I don't know the exact cause, but the fact we were doing
    // API calls in this saga STOPPED the bug happening. Now I've got rid of them, the
    // race condition manifests. This Promise.resolve() is a hack to get around it.
    // Without this Promise.resolve(), the selectedMealPlan below is stale.
    yield Promise.resolve();
    //
    yield put(editAccommodationInitiateBookingBuildRequestAction());
    const bookingManagerApi = makeBookingManagerApi();
    const ownershipData: AxiosResponse<IOwnershipResponse> = yield call(
      bookingManagerApi.getOwnership,
      action.bookingUuid
    );
    const bookingCountryData: AxiosResponse<IBookingCountryResponse> = yield call(
      bookingManagerApi.getBookingCountryCode,
      action.bookingUuid
    );
    const clientCountryCode = bookingCountryData.data.countryCode;
    yield put(editAccommodationSetCountryDataAction(bookingCountryData.data));
    yield put(editAccommodationSetOwnershipDataAction(ownershipData.data));

    const travelAgentUuid = ownershipData.data.ownershipData.taInfo.uuid;
    const backendApi = makeBackendApi(travelAgentUuid);
    const startDate = yield select(BreakdownSelectors.editAccommodationStartDateSelector);
    const endDate = yield select(BreakdownSelectors.editAccommodationEndDateSelector);
    const accommodationDetails: IAccommodationDetails = yield select(BreakdownSelectors.editAccommodationAccommodationDetailsSelector);
    const selectedOccasions = yield select(BreakdownSelectors.editAccommodationOccasionsSelector);
    const repeatCustomer = yield select(BreakdownSelectors.editAccommodationIsRepeatGuestSelector);
    const guestAges = yield select(BreakdownSelectors.editAccommodationGuestAgesSelector);

    const accommodation = accommodationDetails.headlineLineItemBreakdownAccommodationLineItem.requestMeta?.requestSegment.data.attributes.Accommodation[0];
    const bookingBuilderRequest: BookingBuilderRequest = {
      startDate,
      endDate: format(new Date(endDate), 'yyyy-MM-dd'),
      guestAges,
      hotelUuid: accommodationDetails.hotelUuid,
      Accommodation: [
        {
          uuid: accommodation.uuid,
          guestAges,
          repeatCustomer,
          startDate,
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          subProducts: {
            'Meal Plan': accommodation.subProducts['Meal Plan'],
            Supplement: [],
          },
          honeymoon: selectedOccasions.includes('honeymoon'),
          anniversary: selectedOccasions.includes('anniversary'),
          wedding: selectedOccasions.includes('wedding'),
          birthday: selectedOccasions.includes('birthday'),
        },
      ],
      Transfer: [],
      customItems: [],
      'Ground Service': [],
      Supplement: [],
      Fine: [],
    };

    const response: AxiosResponse<{ data: BookingBuilderResponse }> = yield call(backendApi.postBookingBuilderRequest, {
      bookingBuilderRequest,
      clientCountryCode,
      travelAgentUuid,
      queryParams: {
        isForBookingBreakdown: true,
      },
    });

    const errors = (response.data.data.errors || []) as customeErrorType;
    if (errors.length > 0) {
      yield put(editAccommodationSetInitialErrorsAction(errors));
    }
    const selectedMealPlan = response.data.data.availableProductSets.Accommodation[0].availableSubProductSets['Meal Plan'].find(mp => mp.breakdown[0].product.uuid === accommodation.subProducts['Meal Plan'][0].uuid);
    const selectedStaticRate = convertMealPlanToStaticRate(selectedMealPlan);

    response.data.data.totals.total = formatPrice(((action.accommodationDetails.headlineLineItemBreakdownAccommodationLineItem.saleCostCents ?? 0) + (action.accommodationDetails.headlineLineItemBreakdownAccommodationLineItem['Meal Plan'].subtotalCents ?? 0)) / 100)

    yield put(editAccommodationSetSelectedMealPlanAction(!isEmpty(selectedStaticRate) ? selectedStaticRate : undefined));
    yield put(editAccommodationSetBookingBuildAction(response.data.data));
    yield put(editAccommodationSetLastRequestedBuildAction(bookingBuilderRequest));
    yield put(editAccommodationInitiateBookingBuildSuccessAction());
  } catch (e) {
    yield put(editAccommodationInitiateBookingBuildFailedAction());
    console.error('Error', e);
  }
}

export function* editAccommodationBookingBuildSaga() {
  try {
    // we've got a React/Redux race condition due to how we're calling actions
    // and re. this saga. I don't know the exact cause, but the fact we were doing
    // API calls in this saga STOPPED the bug happening. Now I've got rid of them, the
    // race condition manifests. This Promise.resolve() is a hack to get around it.
    // Without this Promise.resolve(), the selectedMealPlan below is stale.
    yield Promise.resolve();
    //

    const ownershipData: IOwnershipResponse | null = yield select(BreakdownSelectors.editAccommodationOwnershipDataSelector);
    const countryData: IBookingCountryResponse | null = yield select(BreakdownSelectors.editAccommodationCountryDataSelector);
    const clientCountryCode = countryData?.countryCode;
    const travelAgentUuid = ownershipData?.ownershipData.taInfo.uuid;
    const backendApi = makeBackendApi(travelAgentUuid);
    const startDate = yield select(BreakdownSelectors.editAccommodationStartDateSelector);
    const endDate = yield select(BreakdownSelectors.editAccommodationEndDateSelector);
    const accommodationDetails: IAccommodationDetails = yield select(BreakdownSelectors.editAccommodationAccommodationDetailsSelector);
    const selectedOccasions = yield select(BreakdownSelectors.editAccommodationOccasionsSelector);
    const selectedMealPlan = (yield select(BreakdownSelectors.editAccommodationSelectedMealPlanSelector)) as IStaticRate | undefined;
    const repeatCustomer = yield select(BreakdownSelectors.editAccommodationIsRepeatGuestSelector);
    const guestAges = yield select(BreakdownSelectors.editAccommodationGuestAgesSelector);

    const accommodation = accommodationDetails.headlineLineItemBreakdownAccommodationLineItem.requestMeta?.requestSegment.data.attributes.Accommodation[0];
    const bookingBuilderRequest: BookingBuilderRequest = {
      startDate: format(new Date(startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(endDate), 'yyyy-MM-dd'),
      guestAges,
      hotelUuid: accommodationDetails.hotelUuid,
      Accommodation: [
        {
          uuid: accommodation.uuid,
          guestAges,
          repeatCustomer,
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          subProducts: {
            'Meal Plan': selectedMealPlan?.mealPlan.uuids.map(uuid => ({ uuid })),
            Supplement: [],
          },
          honeymoon: selectedOccasions.includes('honeymoon'),
          anniversary: selectedOccasions.includes('anniversary'),
          wedding: selectedOccasions.includes('wedding'),
          birthday: selectedOccasions.includes('birthday'),
        },
      ],
      Transfer: [],
      customItems: [],
      'Ground Service': [],
      Supplement: [],
      Fine: [],
    };

    yield put(editAccommodationInitiateBookingBuildRequestAction());
    const response: AxiosResponse<{ data: BookingBuilderResponse }> = yield call(backendApi.postBookingBuilderRequest, {
      bookingBuilderRequest,
      clientCountryCode,
      travelAgentUuid,
      queryParams: {
        isForBookingBreakdown: true,
      },
    });

    const errors = (response.data.data.errors || []) as customeErrorType;
    if (errors.length > 0) {
      yield put(editAccommodationSetErrorsAction(errors));
    }
    yield put(editAccommodationSetBookingBuildAction(response.data.data));
    yield put(editAccommodationSetLastRequestedBuildAction(bookingBuilderRequest));
    yield put(editAccommodationBookingBuildSuccessAction());
  } catch (e) {
    yield put(editAccommodationBookingBuildFailedAction());
    console.error('Error', e);
  }
}

export function* editAccommodationSaveUpdatesSaga(action: EditAccommodationSimulateBreakdownRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const selectedBuild = yield select(BreakdownSelectors.editAccommodationSelectedBuildSelector);
    const lastRequestedBuild = yield select(BreakdownSelectors.editAccommodationLastRequestedBuildSelector);
    const headlineLineItemBreakdown = yield select(BreakdownSelectors.headlineLineItemBreakdownSelector);
    const countryData = yield select(BreakdownSelectors.editAccommodationCountryDataSelector);
    const cancellationPolicies = yield select(BreakdownSelectors.cancellationPoliciesSelector)!;
    const paymentTerms = yield select(BreakdownSelectors.paymentTermsSelector)!;
    const policiesAndRestrictions = yield select(BreakdownSelectors.policiesAndRestrictionsSelector)!;
    const offerTerms = yield select(BreakdownSelectors.offerTermsSelector)!;
    const accomIndex = yield select(BreakdownSelectors.editAccommodationAccommodationIndexSelector)!;

    const simulateBreakdownRequest = {
      bookingBuild: selectedBuild,
      requestedBuild: lastRequestedBuild,
      userCountryCode: countryData,
      headlineLineItemBreakdown,
      cancellationPolicies,
      paymentTerms,
      policiesAndRestrictions,
      offerTerms,
      replaceItems: {
        Accommodation: accomIndex,
      },
    };

    const res = (yield call(bookingManagerApi.postSimulateBreakdown, action.bookingUuid, simulateBreakdownRequest)) as AxiosResponse;
    yield put(updateBreakdownDataAfterAddOrEditAccommodation(
      res.data.headlineLineItemBreakdown,
      res.data.cancellationPolicies,
      res.data.paymentTerms,
      res.data.policiesAndRestrictions,
      res.data.offerTerms,
    ));
    yield put(enqueueNotification({
      message: 'Accommodation edit successfully.',
      options: { variant: 'success' },
    }));
    yield put(editAccommodationSimulateBreakdownSuccessAction());
    yield put(editAccommodationCloseModalAction());
  } catch (e) {
    yield put(editAccommodationSimulateBreakdownFailedAction());
    yield put(
      enqueueNotification({
        message: 'Accommodation failed to edit. Please try again.',
        options: { variant: 'error' },
      })
    );
    console.error('Error', e);
  }
}

export function* watchEditAccommodationBookingBuildSaga() {
  yield takeLatest([EDIT_ACCOMMODATION_OPEN_MODAL], editAccommodationInitiateBookingBuildSaga);
  yield takeLatest([EDIT_ACCOMMODATION_BOOKING_BUILD_REQUEST], editAccommodationBookingBuildSaga);
  yield takeLatest([EDIT_ACCOMMODATION_SIMULATE_BREAKDOWN_REQUEST], editAccommodationSaveUpdatesSaga);
}
