import React from 'react';

import { IIconProps } from 'ui/CircleIconButton';
import { theme } from '../../../../tailwind.config';
export const ContactIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['brown-100'], width = '18', height = '19' }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6.5C12 8.1575 10.6575 9.5 9 9.5C7.3425 9.5 6 8.1575 6 6.5C6 4.8425 7.3425 3.5 9 3.5C10.6575 3.5 12 4.8425 12 6.5ZM3 14C3 12.005 6.9975 11 9 11C11.0025 11 15 12.005 15 14V15.5H3V14Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
      />
    </svg>
  )
);
