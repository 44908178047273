import React from 'react';

import { IIconProps } from 'ui/CircleIconButton';
import { theme } from '../../../../tailwind.config';
export const CompanyIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['brown-100'], width = '18', height = '19' }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5.75H16.5V16.25H1.5V2.75H9V5.75ZM3 14.75H4.5V13.25H3V14.75ZM4.5 11.75H3V10.25H4.5V11.75ZM3 8.75H4.5V7.25H3V8.75ZM4.5 5.75H3V4.25H4.5V5.75ZM6 14.75H7.5V13.25H6V14.75ZM7.5 11.75H6V10.25H7.5V11.75ZM6 8.75H7.5V7.25H6V8.75ZM7.5 5.75H6V4.25H7.5V5.75ZM15 14.75V7.25H9V8.75H10.5V10.25H9V11.75H10.5V13.25H9V14.75H15ZM13.5 8.75H12V10.25H13.5V8.75ZM12 11.75H13.5V13.25H12V11.75Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
      />
    </svg>
  )
);
