import React, { useCallback  } from 'react';
import { useHistory } from 'react-router';
import FluidButton from 'ui/FluidButton';
import { ContactIcon } from 'ui/Icons/components/CrmContact.component';
import { CompanyIcon } from 'ui/Icons/components/Company.component';
import { PageTitle } from 'ui/PageTitle';
import { theme } from '../../../tailwind.config';
import { useSelector } from 'react-redux';
import * as AuthSelectors from 'store/modules/auth';

export const CrmDirectory = () => {
  const history = useHistory();
  const isSr = useSelector(AuthSelectors.isSr);

  const handleCreateCompany = useCallback(() => {
    history.push('/crm/company/create');
  }, [history]);

  const handleCreateContact = useCallback(() => {
    alert('Not implemented');
  }, []);

  return (
    <div className="crm-directory mx-[80px]">
      <PageTitle
        primaryText="Directory"
        secondaryText={`(no results found)`}
        secondaryCondition={true}
      />
      <div className="button-container flex justify-end">
        <div className="buttons-container-right flex items-center gap-[10px]">
          <FluidButton type="primary" textClassName="flex items-center gap-[10px]" onClick={handleCreateCompany} disabled={isSr}>
            New Company
            <CompanyIcon fill={theme.colors['white']} />
          </FluidButton>
          <FluidButton type="primary" textClassName="flex items-center gap-[10px]" onClick={handleCreateContact}>
            New Contact
            <ContactIcon fill={theme.colors['white']} />
          </FluidButton>
        </div>
      </div>
    </div>
  )
};
