import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { BackLink } from 'ui/BackLink';
import { PageTitle } from 'ui/PageTitle';
import { companyCreateFormValidationSchema, ICompanyCreateFormValues } from './companyCreateFormValidation';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { TextInput } from 'ui/TextInput';
import Editor from 'pureUi/Editor';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { makeCRMApi } from 'services/CrmApi';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { PageSectionTitle } from 'ui/PageSectionTitle';
import { VerticalSpace } from 'ui/VerticalSpace';
import FluidButton from 'ui/FluidButton';
import { enqueueNotification } from 'store/modules/ui';
import { useHistory } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { useResponsive } from './hooks/useResponsive';

export const CrmCompanyCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useResponsive();

  const crmApi = makeCRMApi();
  const { isPending: isCountriesPending, isError: isCountriesError, data: countries } = useQuery({
    queryKey: ['crm-countries'],
    queryFn: () =>
      crmApi.postCountries(),
  });
  const { isPending: isTypeValuesPending, isError: isTypeValuesError, data: typeValues } = useQuery({
    queryKey: ['crm-type-values'],
    queryFn: () =>
      crmApi.postTypeValues(ETypeValueType.COMPANY, { enabled: true }),
  });
  
  const handleFormSubmit = useCallback(async (values: ICompanyCreateFormValues, formikHelpers: FormikHelpers<ICompanyCreateFormValues>) => {
    dispatch(
      enqueueNotification({
        message: 'Company created',
        options: { variant: 'success' },
      })
    );
    await formikHelpers.resetForm();

    history.push('/crm');
  }, [history, dispatch]);

  const countryOptions: ISingleSelectOption[] = countries?.data?.map(
    item => ({ value: item.code, label: item.name })
  ) ?? [];

  const typeOptions: ISingleSelectOption[] = typeValues?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  const initialValues: ICompanyCreateFormValues = useMemo(() => ({
    type: null,
    name: '',
    country: null,
    keyNotes: '',
  }), []);

  if (isCountriesPending || isTypeValuesPending) {
    return (
      <div className="mt-[20px]">
        <LoadingBar />
      </div>
    )
  }

  if (isCountriesError || isTypeValuesError) {
    return (
      <div className="mt-[20px]">
        <ErrorBar />
      </div>
    )
  }

  const selectClassname = classnames('min-w-[190px]', {
    'w-full': isMobile,
    'w-[200px]': !isMobile,
  });
  const saveButtonClassname = classnames({
    'w-full': isMobile,
  });
  
  return (
    <div className="crm-directory mx-[20px] crm-desktop:mx-[80px]">
      <BackLink type="internal" href="/crm">
        Back
      </BackLink>
      <PageTitle
        primaryText="New Company"
        secondaryCondition={false}
      />
      <VerticalSpace height="20px" />
      <PageSectionTitle
        text="Basic Info"
      />
      <Formik
          initialValues={initialValues}
          validationSchema={companyCreateFormValidationSchema}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {(form: FormikProps<ICompanyCreateFormValues>) => {  
            return (
              <>
                <LeaveWithoutSavingModal 
                  when={form.dirty} 
                  confirmButtonLabel="Yes"
                  cancelButtonLabel="No"
                />

                <form autoComplete={'off'} onSubmit={form.handleSubmit}>
                  <div className="form-container flex flex-col justify-between gap-[20px] mt-[10px]">
                    <div className="type-name-country flex flex-col crm-tablet:flex-row items-start gap-[20px]">
                      <Field name="type">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <SingleSelect
                            fieldId="type"
                            label="Type *"
                            className={classnames('type', selectClassname)}
                            value={value}
                            showSelectedOption
                            maxVisibleItems={isMobile ? 4 : 6}
                            options={typeOptions ?? []}
                            labelWhenNothingSelected="None"
                            onChange={value => {
                              setFieldValue(name, value);
                            }}
                            errorMessage={form.touched.type && form.errors.type ? form.errors.type : null}
                            errorClassName="type-error"
                          />
                        )}
                      </Field>
                      <Field
                        as={TextInput}
                        type="text"
                        name="name"
                        id="name"
                        className="name flex-1 w-full desktop:max-w-[480px]"
                        label="Name *"
                        errorMessage={form.touched.name && form.errors.name ? form.errors.name : null}
                        errorClassName="name-error"
                      />
                      <Field name="country">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <SingleSelect
                            fieldId="country"
                            label="Country *"
                            className={classnames('country', selectClassname)}
                            value={value}
                            maxVisibleItems={isMobile ? 4 : 6}
                            showSelectedOption
                            options={countryOptions ?? []}
                            labelWhenNothingSelected="None"
                            onChange={value => {
                              setFieldValue(name, value);
                            }}
                            errorMessage={form.touched.country && form.errors.country ? form.errors.country : null}
                            errorClassName="country-error"
                          />
                        )}
                      </Field>
                    </div>
                    <div className="key-notes flex  gap-[20px]">
                      <Field name="keynotes">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <div className="flex flex-col w-full">
                            <label className="font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs">
                              Key Notes
                            </label>
                            <Editor text={value} handleEditorChange={(value) => setFieldValue(name, value)} options={[]} mentionAbility={false} />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                <div className="buttons-container mt-[20px]">
                  <FluidButton submit type="primary" textClassName="flex items-center gap-[10px]" className={saveButtonClassname}>
                     Save
                  </FluidButton>
                </div>
                </form>
              </>
            );
          }}
        </Formik>
    </div>
  )
};
